import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Code from '@material-ui/icons/Code'
import Button from '@material-ui/core/Button';
import Voicemail from '@material-ui/icons/Voicemail'
import Battery30 from '@material-ui/icons/Battery30'
import DonutSmall from '@material-ui/icons/DonutSmall'
import Build from '@material-ui/icons/Build'
import DataUsage from '@material-ui/icons/DataUsage'
import KeyboardHide from '@material-ui/icons/KeyboardHide'
import BPage from '../BPage'
import RuleTp4 from '../ruletp/RuleTp4';

import { MONEY_1, MONEY_2, MONEY_3, MONEY_4, MONEY_5, MONEY_6, MONEY_7 } from '../ruletp/RuleData'
import {
    MONEY_1_IDS, MONEY_2_IDS, MONEY_3_IDS, MONEY_4_IDS,
    MONEY_5_IDS, MONEY_6_IDS, MONEY_7_IDS
} from '../ruletp/RuleData'

import { enableColor, disableColor } from '../util/Expansion'
import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';


const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            padding: 15,
        },
        [theme.breakpoints.up('lg')]: {
            padding: 15,
        },
    },
    panelTitle: {
        marginLeft: 10,
    },
    panelTitle1: {
        marginLeft: 10,
    },
    divider: {
        margin: 15,
        backgroundColor: "#E7E7E7"
    },
    titleLabel: {
        fontSize: 20,
        fontWeight: 350,
        marginBottom: 0,
        color: theme.palette.primary.dark
    },
    chip: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#FFFFFF",
        backgroundColor: "#D80C67"
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
    titlea: {
        backgroundColor: '#FFFFFF',
        opacity: 1
    }
})


class MoneyDashboard extends React.Component {
    state = {
        expanded: 'panelx',
    };
    handleOpen1 = () => {
        this.props.tabchange(BPage.donate_page)
    }
    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    render() {
        const { expanded } = this.state;
        const { classes } = this.props;

        const isMoneyOn1 = this.props.rules.some(rule => MONEY_1_IDS.includes(rule.id));
        const isMoneyOn2 = this.props.rules.some(rule => MONEY_2_IDS.includes(rule.id));
        const isMoneyOn3 = this.props.rules.some(rule => MONEY_3_IDS.includes(rule.id));
        const isMoneyOn4 = this.props.rules.some(rule => MONEY_4_IDS.includes(rule.id));
        const isMoneyOn5 = this.props.rules.some(rule => MONEY_5_IDS.includes(rule.id));
        const isMoneyOn6 = this.props.rules.some(rule => MONEY_6_IDS.includes(rule.id));
        const isMoneyOn7 = this.props.rules.some(rule => MONEY_7_IDS.includes(rule.id));

        return (
            <div className={classes.root}>
                <div>
                    {/* <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.handleOpen1()}>
                訂閱
                    </Button>    
                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.props.saveRule1()}>
                        儲存規則
                    </Button> */}
                    {this.props.tabchange != undefined ?
                        <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.handleOpen1()}>
                            訂閱
                        </Button> : null
                    }

                    {this.props.saveRule1 != undefined ?
                        <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.props.saveRule1()}>
                            儲存規則
                        </Button> : null
                    }
                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.props.clearRule()}>
                        清除規則
                    </Button>

                </div>


                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'MoneyOn1'}
                    onChange={this.handleChange('MoneyOn1')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isMoneyOn1 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Battery30 color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>融資、融券</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {MONEY_1.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'MoneyOn2'}
                    onChange={this.handleChange('MoneyOn2')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isMoneyOn2 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Code color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>三大法人</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {MONEY_2.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'MoneyOn3'}
                    onChange={this.handleChange('MoneyOn3')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isMoneyOn3 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <KeyboardHide color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>主力、中實戶、券商</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {MONEY_3.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'MoneyOn4'}
                    onChange={this.handleChange('MoneyOn4')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isMoneyOn4 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <DataUsage color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>大戶、散戶〔多方〕</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {MONEY_4.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'MoneyOn5'}
                    onChange={this.handleChange('MoneyOn5')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isMoneyOn5 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <DonutSmall color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>大戶、散戶〔空方〕</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {MONEY_5.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'MoneyOn6'}
                    onChange={this.handleChange('MoneyOn6')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isMoneyOn6 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Build color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>買賣家數差、籌碼集中度、周轉率、券資比</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {MONEY_6.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'MoneyOn7'}
                    onChange={this.handleChange('MoneyOn7')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isMoneyOn7 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Voicemail color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>八大官股、當沖率、董監事</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {MONEY_7.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

export default withStyles(styles)(MoneyDashboard);