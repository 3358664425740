import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MainCusDetail from './MainCusDetail'
import CashDetail from './CashDetail'
import RevDetail from './RevDetail'
import InterestDetail from './InterestDetail'
import WinMDetail from '../mobile3/WinMDetail'
import BrokerDetail from './BrokerDetail'
import BrokerBuyDetail from './BrokerBuyDetail'
import CBDetail from './CBDetail'
import FinDetail from './FinDetail'
import BIASMDetail from './BIASMDetail';
import MEPSDetail from './MEPSDetail';
import BrokerAvgDetail from './BrokerAvgDetail'

import MFinRateDetail from './MFinRateDetail';
import MMainCostDetail1 from './MMainCostDetail1'
import RiskDetail from './RiskDetail';
import MStockETF from './MStockETF'
import MHighLowDetail from './MHighLowDetail'
import CashChart from './CashChart1'
import LoanDetail from '../kdmacd/LoanDetail1'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from "@material-ui/core/styles/withStyles";
import CashReport from './CashReport';

import StockPressure from './StockPressure'
import StockCMF from './StockCMF'
import StockRunning from './StockRunning'
import KD from '../kdmacd/KD'
import DuKong from '../dk/DuKong';
import WinRateLine from '../dk/WinRateLine'
import BaTa from '../kdmacd/BaTa'
import StockCost from '../dk/StockCost';
import Scant from '../kdmacd/Scant';

import MBigSmallTable from '../mobile3/MBigSmallTable';
import MRev1Detail from '../mobile3/MRev1Detail';
import DMIChart from '../mobile3/DMIChart'
import TurnDetail from '../mobile3/TurnDetail';
import NineChart from '../mobile3/NineChart'
import TLBChart from '../mobile3/TLBChart'
import HLPChart from '../mobile3/HLPChart';
import CategoryInfo from '../mobile3/CategoryInfo'
import Fakk from '../mobile3/Fakk';
import Fakk1 from '../mobile3/Fakk1';
import RevWow from '../mobile3/RevWow'
import RSIChart from '../mobile3/RSIChart';
import TrendChart from '../mobile3/TrendChart'

// import DrawChart from '../mobile3/DrawChart';
import PressureIndex from '../dk/PressureIndex';
import StopValue from '../dk/StopValue';
import CCI from '../kdmacd/CCI';
import AA from '../kdmacd/AA';
import NotificationF from '../kdmacd/NotificationF';
import FutureTurn from '../kdmacd/FutureTurn';
import Alligator from '../dk/Alligator';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 15, paddingBottom: 15 }} p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function a12yProps(index) {
    return {
        id: `scrollable-auto-tab2-${index}`,
        'aria-controls': `scrollable-auto-tabpanel2-${index}`,
    };
}


function a13yProps(index) {
    return {
        id: `scrollable-auto-tab3-${index}`,
        'aria-controls': `scrollable-auto-tabpanel3-${index}`,
    };
}


function a14yProps(index) {
    return {
        id: `scrollable-auto-tab4-${index}`,
        'aria-controls': `scrollable-auto-tabpanel4-${index}`,
    };
}

function a15yProps(index) {
    return {
        id: `scrollable-auto-tab5-${index}`,
        'aria-controls': `scrollable-auto-tabpanel5-${index}`,
    };
}

function a16yProps(index) {
    return {
        id: `scrollable-auto-tab6-${index}`,
        'aria-controls': `scrollable-auto-tabpanel6-${index}`,
    };
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        // backgroundColor: theme.palette.background.default,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
}));

export default function StockDetail2(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [subtabIndex, setSubtabIndex] = React.useState(0);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);

    const handleChange = (event, newValue) => {
        // if (newValue === 3 || newValue === 1 || newValue === 2 || newValue === 4) {
        // } else {
        setValue(newValue)
        setSubtabIndex(0)
        // if (newValue === 1 || newValue === 4) {
        //     setSubtabIndex(0)
        // } else if (newValue === 2) {
        //     setSubtabIndex(8)
        // } else if (newValue === 3) {
        //     setSubtabIndex(5)
        // }

        // }
    };

    const handleCloseMenua = (i, s) => {
        setAnchorEl(null)
        setAnchorEl1(null)
        setAnchorEl2(null)
        setAnchorEl3(null)

        setValue(i)
        setSubtabIndex(s)
    };

    const getSubTab = () => {
        if (value === 1) {
            return <Tabs
                color="secondary"
                value={subtabIndex}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab onClick={(e) => handleCloseMenua(1, 0)} label="籌碼日週報" {...a12yProps(0)} />
                <Tab onClick={(e) => handleCloseMenua(1, 1)} label="融資融券" {...a12yProps(1)} />
                <Tab onClick={(e) => handleCloseMenua(1, 2)} label="主力" {...a12yProps(2)} />
                <Tab onClick={(e) => handleCloseMenua(1, 3)} label="大戶散戶" {...a12yProps(3)} />
                <Tab onClick={(e) => handleCloseMenua(1, 4)} label="囤貨券商" {...a12yProps(4)} />
                <Tab onClick={(e) => handleCloseMenua(1, 5)} label="券商買超天數" {...a12yProps(5)} />
                <Tab onClick={(e) => handleCloseMenua(1, 6)} label="券商異常交易" {...a12yProps(6)} />
                <Tab onClick={(e) => handleCloseMenua(1, 7)} label="成本線" {...a12yProps(7)} />
            </Tabs>
        } else if (value === 2) {
            return <Tabs
                value={subtabIndex}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab onClick={(e) => handleCloseMenua(2, 0)} label="財務報表" {...a13yProps(0)} />
                <Tab onClick={(e) => handleCloseMenua(2, 1)} label="產業類別" {...a13yProps(1)} />
                <Tab onClick={(e) => handleCloseMenua(2, 2)} label="價值評估" {...a13yProps(2)} />
                <Tab onClick={(e) => handleCloseMenua(2, 3)} label="風險評估" {...a13yProps(3)} />
                <Tab onClick={(e) => handleCloseMenua(2, 4)} label="營收" {...a13yProps(4)} />
                <Tab onClick={(e) => handleCloseMenua(2, 5)} label="營收動能" {...a13yProps(5)} />
                <Tab onClick={(e) => handleCloseMenua(2, 6)} label="三率" {...a13yProps(6)} />
                <Tab onClick={(e) => handleCloseMenua(2, 7)} label="除權息" {...a13yProps(7)} />
                <Tab onClick={(e) => handleCloseMenua(2, 8)} label="EPS" {...a13yProps(8)} />
                <Tab onClick={(e) => handleCloseMenua(2, 9)} label="股價本益比" {...a13yProps(9)} />
                <Tab onClick={(e) => handleCloseMenua(2, 10)} label="庫藏股(訂閱)" {...a13yProps(10)} />
                <Tab onClick={(e) => handleCloseMenua(2, 11)} label="注意/處置(訂閱)" {...a13yProps(11)} />

            </Tabs>
        } else if (value === 3) {
            return <Tabs
                value={subtabIndex}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab onClick={(e) => handleCloseMenua(3, 0)} label="大盤勝負" {...a14yProps(0)} />
                <Tab onClick={(e) => handleCloseMenua(3, 1)} label="KD/MACD" {...a14yProps(1)} />
                <Tab onClick={(e) => handleCloseMenua(3, 2)} label="通道" {...a14yProps(2)} />
                <Tab onClick={(e) => handleCloseMenua(3, 3)} label="SAR/乖離率" {...a14yProps(3)} />
                <Tab onClick={(e) => handleCloseMenua(3, 4)} label="DMI" {...a14yProps(4)} />
                <Tab onClick={(e) => handleCloseMenua(3, 5)} label="寶塔線" {...a14yProps(5)} />
                <Tab onClick={(e) => handleCloseMenua(3, 6)} label="CCI/威廉/DMA" {...a14yProps(6)} />
                <Tab onClick={(e) => handleCloseMenua(3, 7)} label="RSI" {...a14yProps(7)} />
            </Tabs>
        } else if (value === 4) {
            return <Tabs
                value={subtabIndex}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab onClick={(e) => handleCloseMenua(4, 0)} label="ETF" {...a15yProps(0)} />
                <Tab onClick={(e) => handleCloseMenua(4, 1)} label="可轉債" {...a15yProps(1)} />
            </Tabs>
        } else if (value === 5) {
            return <Tabs
                value={subtabIndex}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab onClick={(e) => handleCloseMenua(5, 0)} label="分價量支撐壓力" {...a16yProps(0)} />
                <Tab onClick={(e) => handleCloseMenua(5, 1)} label="轉折波趨勢線" {...a16yProps(1)} />
                <Tab onClick={(e) => handleCloseMenua(5, 2)} label="九轉序列" {...a16yProps(2)} />
                <Tab onClick={(e) => handleCloseMenua(5, 3)} label="新三價線" {...a16yProps(3)} />
                <Tab onClick={(e) => handleCloseMenua(5, 4)} label="股價趨勢圖" {...a16yProps(4)} />
                <Tab onClick={(e) => handleCloseMenua(5, 5)} label="資金流向指標" {...a16yProps(5)} />
                <Tab onClick={(e) => handleCloseMenua(5, 6)} label="轉折預測圖" {...a16yProps(6)} />
                <Tab onClick={(e) => handleCloseMenua(5, 7)} label="吊燈指標" {...a16yProps(7)} />
                <Tab onClick={(e) => handleCloseMenua(5, 8)} label="鱷魚指標" {...a16yProps(8)} />
                {/* <Tab onClick={(e) => handleCloseMenua(5, 6)} label="衝刺指標圖" {...a16yProps(6)} />
                <Tab onClick={(e) => handleCloseMenua(5, 7)} label="串聯指標圖" {...a16yProps(7)} />
                <Tab onClick={(e) => handleCloseMenua(5, 8)} label="期望指標圖" {...a16yProps(8)} /> */}

            </Tabs>
        } else if (value === 6) {
            return <Tabs
                value={subtabIndex}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab onClick={(e) => handleCloseMenua(6, 0)} label="三方指標圖" {...a16yProps(0)} />
                <Tab onClick={(e) => handleCloseMenua(6, 1)} label="衝刺指標圖" {...a16yProps(1)} />
                <Tab onClick={(e) => handleCloseMenua(6, 2)} label="串聯指標圖" {...a16yProps(2)} />
                <Tab onClick={(e) => handleCloseMenua(6, 3)} label="期望指標圖" {...a16yProps(3)} />
                <Tab onClick={(e) => handleCloseMenua(6, 4)} label="K線預測圖" {...a16yProps(4)} />
                <Tab onClick={(e) => handleCloseMenua(6, 5)} label="K線分割圖" {...a16yProps(5)} />
                <Tab onClick={(e) => handleCloseMenua(6, 6)} label="成長比較圖" {...a16yProps(6)} />
                <Tab onClick={(e) => handleCloseMenua(6, 7)} label="支壓圖" {...a16yProps(7)} />
                <Tab onClick={(e) => handleCloseMenua(6, 8)} label="挺立圖" {...a16yProps(8)} />
            </Tabs>
        } else return null
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs

                    value={value}
                    onChange={handleChange}
                    // indicatorColor="primary"
                    // textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label="基本資料" {...a11yProps(0)} />
                    <Tab label="籌碼面" {...a11yProps(1)} />
                    <Tab label="基本面" {...a11yProps(2)} />
                    <Tab label="技術面" {...a11yProps(3)} />
                    <Tab label="ETF/可轉債" {...a11yProps(4)} />
                    <Tab label="訂閱專屬1" {...a11yProps(5)} />
                    <Tab label="訂閱專屬2(創新)" {...a11yProps(6)} />

                    {/* <Tab label="分價量支撐壓力" {...a11yProps(5)} />
                    <Tab label="轉折波趨勢線" {...a11yProps(6)} />
                    <Tab label="股價趨勢圖" {...a11yProps(7)} />
                    <Tab label="九轉序列/衝刺圖" {...a11yProps(8)} />
                    <Tab label="新三價線" {...a11yProps(9)} /> */}
                </Tabs>
                {/* style={{ backgroundColor: "#e3e3e3" }} */}

            </AppBar>
            <div>
                {getSubTab()}
            </div>
            {value === 0 ? <TabPanel style={{ overflowScrolling: 'touch', WebkitOverflowScrolling: 'touch' }} value={value} index={0}>
                <FinDetail row={props.row} bigtitle={props.bigtitle} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}

            {value === 1 && subtabIndex === 0 ? <TabPanel value={value} index={1}>
                <MainCusDetail row={props.row} bigtitle={props.bigtitle} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 1 && subtabIndex === 1 ? <TabPanel value={value} index={1}>
                <LoanDetail row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 1 && subtabIndex === 2 ? <TabPanel value={value} index={1}>
                <MMainCostDetail1 row={props.row} bigtitle={props.bigtitle} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 1 && subtabIndex === 3 ? <TabPanel value={value} index={1}>
                <MBigSmallTable row={props.row} bigtitle={props.bigtitle} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 1 && subtabIndex === 4 ? <TabPanel value={value} index={1}>
                <BrokerDetail row={props.row} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 1 && subtabIndex === 5 ? <TabPanel value={value} index={1}>
                <BrokerBuyDetail row={props.row} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 1 && subtabIndex === 6 ? <TabPanel value={value} index={1}>
                <BrokerAvgDetail row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 1 && subtabIndex === 7 ? <TabPanel value={value} index={1}>
                <StockCost row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}

            {value === 2 && subtabIndex === 0 ? <TabPanel value={value} index={2}>
                <CashReport row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 2 && subtabIndex === 1 ? <TabPanel value={value} index={2}>
                <CategoryInfo row={props.row} bigtitle={props.bigtitle} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}

            {value === 2 && subtabIndex === 2 ? <TabPanel value={value} index={2}>
                <CashDetail row={props.row} bigtitle={props.bigtitle} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 2 && subtabIndex === 3 ? <TabPanel value={value} index={2}>
                <RiskDetail row={props.row} bigtitle={props.bigtitle} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 2 && subtabIndex === 4 ? <TabPanel value={value} index={2}>
                <RevDetail row={props.row} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 2 && subtabIndex === 5 ? <TabPanel value={value} index={2}>
                <MRev1Detail row={props.row} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 2 && subtabIndex === 6 ? <TabPanel value={value} index={2}>
                <MFinRateDetail row={props.row} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 2 && subtabIndex === 7 ? <TabPanel value={value} index={2}>
                <InterestDetail row={props.row} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 2 && subtabIndex === 8 ? <TabPanel value={value} index={2}>
                <MEPSDetail row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 2 && subtabIndex === 9 ? <TabPanel value={value} index={2}>
                <MHighLowDetail row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 2 && subtabIndex === 10 ? <TabPanel value={value} index={2}>
                <AA row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 2 && subtabIndex === 11 ? <TabPanel value={value} index={2}>
                <NotificationF row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}

            {value === 3 && subtabIndex === 0 ? <TabPanel value={value} index={3}>
                <WinMDetail row={props.row} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 3 && subtabIndex === 1 ? <TabPanel value={value} index={3}>
                <KD row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
                {/* <MKDDetail row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile}/> */}
            </TabPanel> : null}
            {/* {value === 3 && subtabIndex === 2 ? <TabPanel value={value} index={3}>
                <MACD row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile}/>
            </TabPanel> : null} */}
            {value === 3 && subtabIndex === 2 ? <TabPanel value={value} index={3}>
                <Scant row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 3 && subtabIndex === 3 ? <TabPanel value={value} index={3}>
                <BIASMDetail row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 3 && subtabIndex === 4 ? <TabPanel value={value} index={3}>
                <DMIChart row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 3 && subtabIndex === 5 ? <TabPanel value={value} index={3}>
                <BaTa row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 3 && subtabIndex === 6 ? <TabPanel value={value} index={3}>
                <CCI row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 3 && subtabIndex === 7 ? <TabPanel value={value} index={3}>
                <RSIChart row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}

            {value === 4 && subtabIndex === 1 ? <TabPanel value={value} index={4}>
                <CBDetail row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 4 && subtabIndex === 0 ? <TabPanel value={value} index={4}>
                <MStockETF row={props.row} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}

            {value === 5 && subtabIndex === 0 ? <TabPanel value={value} index={5}>
                <StockPressure row={props.row} bigtitle={props.bigtitle} bigupdate={props.bigupdate} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 5 && subtabIndex === 1 ? <TabPanel value={value} index={5}>
                <TurnDetail row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 5 && subtabIndex === 2 ? <TabPanel value={value} index={5}>
                <NineChart row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 5 && subtabIndex === 3 ? <TabPanel value={value} index={5}>
                <TLBChart row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 5 && subtabIndex === 4 ? <TabPanel value={value} index={5}>
                <CashChart row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 5 && subtabIndex === 5 ? <TabPanel value={value} index={5}>
                <StockCMF row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 5 && subtabIndex === 6 ? <TabPanel value={value} index={5}>
                <FutureTurn row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 5 && subtabIndex === 7 ? <TabPanel value={value} index={5}>
                <StopValue row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}            
            {value === 5 && subtabIndex === 8 ? <TabPanel value={value} index={5}>
                <Alligator row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}  

            {value === 6 && subtabIndex === 0 ? <TabPanel value={value} index={6}>
                <HLPChart row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 6 && subtabIndex === 1 ? <TabPanel value={value} index={6}>
                <StockRunning row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 6 && subtabIndex === 2 ? <TabPanel value={value} index={6}>
                <DuKong row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 6 && subtabIndex === 3 ? <TabPanel value={value} index={6}>
                <WinRateLine row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 6 && subtabIndex === 4 ? <TabPanel value={value} index={6}>
                <Fakk row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 6 && subtabIndex === 5 ? <TabPanel value={value} index={6}>
                <Fakk1 row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}

            {value === 6 && subtabIndex === 6 ? <TabPanel value={value} index={6}>
                <RevWow row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}

            {value === 6 && subtabIndex === 7 ? <TabPanel value={value} index={6}>
                <PressureIndex row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}
            {value === 6 && subtabIndex === 8 ? <TabPanel value={value} index={6}>
                <TrendChart row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null}


            {/* {value === 6 && subtabIndex === 4 ? <TabPanel value={value} index={6}>
                <DrawChart row={props.row} bigupdate={props.bigupdate} toolbar={props.toolbar} ismobile={props.ismobile} />
            </TabPanel> : null} */}

        </div>
    );
}
